import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Extract tenant name from the subdomain
    const tenant = window.location.hostname.split('.')[0]; // Example: tenant.subdomain.com
    setWelcomeMessage(`Welcome to ${tenant}`);
  }, []);

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div>
      <h2>Dashboard</h2>
      <p>{welcomeMessage}</p>
      {/* User functionalities */}
      <button onClick={handleLogout} style={{ marginTop: '20px' }}>Logout</button>
    </div>
  );
}

export default Dashboard;
