import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = () => {
    // Extract tenant name from the URL or another method
    const tenantName = window.location.hostname.split('.')[0]; // Example: tenant.subdomain.com

    axios.post(`https://tenantbackend.onrender.com/auth/register/${tenantName}`, { username, password })
      .then(response => {
        alert(response.data.message);
        if (response.data.message === "User registered successfully") {
          navigate('/'); // Navigate to login page on successful registration
        }
      })
      .catch(error => {
        alert('Error registering user');
      });
  };

  return (
    <div className="container flex flex-center flex-column" style={{ height: '100vh' }}>
      <div className="form-container">
        <h2>Register</h2>
        <input
          type="text"
          placeholder="Username"
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
          value={password}
        />
        <button onClick={handleRegister}>Register</button>
        <p>
          Already have an account? <Link to="/">Login</Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
