import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Extract tenant name from the URL or another method
    const tenantName = window.location.hostname.split('.')[0]; // Example: tenant.subdomain.com

    axios.post(`https://tenantbackend.onrender.com/auth/login/${tenantName}`, { username, password })
      .then(response => {
        if (response.data.message === "Login successful") {
          localStorage.setItem('userRole', response.data.role);
          navigate(response.data.role === 'admin' ? '/admin-dashboard' : '/dashboard');
        } else {
          alert(response.data.message);
        }
      })
      .catch(error => {
        alert('Error logging in');
      });
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <div className="login-form">
        <input
          type="text"
          placeholder="Username"
          onChange={e => setUsername(e.target.value)}
          value={username}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
          value={password}
        />
        <button onClick={handleLogin}>Login</button>
        <p>
          Don't have an account? <Link to="/register">Sign Up</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
