import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AdminDashboard() {
  const [tenantName, setTenantName] = useState('');
  const [purpose, setPurpose] = useState('');
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://tenantbackend.onrender.com/tenant/list/')
      .then(response => {
        setTenants(response.data.tenants);
      })
      .catch(error => {
        console.error('Error fetching tenants', error);
      });
  }, []);

  const handleAddTenant = () => {
    axios.post('https://tenantbackend.onrender.com/tenant/add/', { name: tenantName, purpose })
      .then(response => {
        alert(response.data.message);
        setTenantName('');
        setPurpose('');
        // Refresh tenant list
        axios.get('https://tenantbackend.onrender.com/tenant/list/')
          .then(response => {
            setTenants(response.data.tenants);
          });
      })
      .catch(error => {
        alert('Error adding tenant');
      });
  };

  const handleCopyLink = (tenantName) => {
    const tenantUrl = `https://${tenantName}.comradecultss.shop`;
    navigator.clipboard.writeText(tenantUrl)
      .then(() => {
        alert(`Copied to clipboard: ${tenantUrl}`);
      })
      .catch(() => {
        alert('Failed to copy link');
      });
  };

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div>
      <h2>Admin Dashboard</h2>
      <input
        type="text"
        placeholder="Tenant Name"
        value={tenantName}
        onChange={e => setTenantName(e.target.value)}
      />
      <textarea
        placeholder="Purpose"
        value={purpose}
        onChange={e => setPurpose(e.target.value)}
      ></textarea>
      <button onClick={handleAddTenant}>Add Tenant</button>
      <button onClick={handleLogout} style={{ marginTop: '20px' }}>Logout</button>

      <h3>Tenant List</h3>
      <ul>
        {tenants.map(tenant => (
          <li key={tenant.name}>
            {tenant.name} - {tenant.purpose}
            <button onClick={() => handleCopyLink(tenant.name)}>Copy Link</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminDashboard;
